export default {
  staticLinearGradientRight: 'linear-gradient(90deg, #790049, #610048)',
  staticLinearGradientLeft: 'linear-gradient(90deg, #91004B, #790049)',
  staticTiltedGradient: 'linear-gradient(114deg, #91004B, #610048)',
  staticLinearGradient: 'linear-gradient(90deg, #91004B, #610048)',
  primaryCherry: '#91004B',
  altCherry: '#610048',
  primaryGrey: '#6E6E6E',
  lightGrey: '#EBEBEB',
  black: '#3D3F46',
  white: '#FFF',
  minWidthDesktop: 1700,
  minWidthLaptop: 1024,
  minWidthTablet: 640,
  bodyFont: 'Lato, sans-serif',
  headerFont: 'Lora, serif',
};
