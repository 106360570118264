import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const seoKeywords = [
  'healthcare creative agency',
  'award-winning creative agency',
  'med comms',
  'medical communications',
  'healthcare communications',
  'comms agency',
  'OTC campaign',
  'secondary care campaign',
  'primary care campaign',
  'healthcare advertising agency',
  'best healthcare comms agency',
  'top healthcare communications agency',
  'digital healthcare',
  'healthcare strategic consultancy',
  'positioning',
  'brand planning',
  'global launch',
  'medical writing',
  'privacy',
  'cookies policy',
  'cherry legal',
  'Medical devices',
  'nutritional marketing agency',
  'dental marketing agency',
  'animal health',
  'pharmaceutical marketing agency',
  'sexual health agency',
  'biotech marketing agency',
  'drug launch agency',
  'prescription medicine launch agency',
];

function SEO({ description, lang, meta, keywords, title, GAEnabled }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`${data.site.siteMetadata.title} | %s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.concat(seoKeywords).join(`, `),
                    }
                  : [],
              )
              .concat(meta)}
          >
            {GAEnabled ? (
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-ET2PEYVCY8"
              ></script>
            ) : null}
            {GAEnabled ? (
              <script>
                {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-ET2PEYVCY8');`}
              </script>
            ) : null}
            <script>
              {`var _paq = (window._paq = window._paq || []);
                /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function () {
                  var u = '//matomo.cherrythinkingdev.com/matomo/';
                  _paq.push(['setTrackerUrl', u + 'matomo.php']);
                  _paq.push(['setSiteId', '1']);
                  var d = document,
                    g = d.createElement('script'),
                    s = d.getElementsByTagName('script')[0];
                  g.async = true;
                  g.src = u + 'matomo.js';
                  s.parentNode.insertBefore(g, s);
                })();`}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
