import styled from 'styled-components';

export const StyledFooter = styled.div`
  width: 100%;
  background: #3d3f46;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 13px;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding-left: 7.5%;
    padding-right: 5%;
  }
`;

export const StyledFooterLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    flex-direction: row;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
`;

export const StyledFooterLinks = styled.a`
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  color: white;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  :hover {
    opacity: 0.7;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    &:not(:last-child) {
      margin-bottom: 0px;
      margin-right: 15px;
    }
  }
`;

export const BrandLogoContainer = styled.div`
  svg {
    width: 100px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    svg {
      width: 168px;
    }

    border-right: 1px solid #fff;
    padding-right: 30px;
    margin-right: 30px;
  }
`;

export const FooterRow = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: ${(props) =>
      props.justified
        ? 'space-between'
        : props.reverse
        ? 'flex-end'
        : 'flex-start'};

    display: flex;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export const StyledFooterInfo = styled.p`
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  opacity: 0.5;
  margin-bottom: 15px;

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    margin-bottom: 0;
  }
`;

// export const StyledFooterRule = styled.div`
//   @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
//     height: 100%;
//     width: 1px;
//     background: white;
//     grid-row: 2 / span 1;
//     grid-column: 3 / span 1;
//     justify-self: center;

//     /* -ms-grid-row: 2;
//     -ms-grid-row-span: 1;
//     -ms-grid-column: 3;
//     -ms-grid-column-span: 1;

//     -ms-grid-column-align: center; */
//   }
// `;

export const StyledLogo = styled.a`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.marginTop};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    width: ${(props) => props.widthTab};
    height: ${(props) => props.heightTab};
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    width: ${(props) => props.widthLaptop};
    height: ${(props) => props.heightLaptop};
    justify-content: flex-start;
  }
`;

export const StyledLogoContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${(props) => props.marginTop};

  svg {
    min-width: 75px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* width: 100%;
    height: 100%; */
    margin-top: 0;
    justify-content: ${(props) => props.justifyTab};

    /* -ms-grid-row: ${(props) => props.msrow};
    -ms-grid-row-span: ${(props) => props.msrowspan};
    -ms-grid-column: ${(props) => props.mscol};
    -ms-grid-column-span: ${(props) => props.mscolspan}; */

    grid-row: ${(props) => props.rowTab};
    grid-column: ${(props) => props.colTab};

    margin-top: ${(props) => props.marginTopTab};
    margin-left: ${(props) => props.marginLeftTab};

    svg {
      width: ${(props) => props.logoWidthTab};
      height: ${(props) => props.logoHeightTab};
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    grid-row: ${(props) => props.rowLaptop};
    grid-column: ${(props) => props.colLaptop};

    /* -ms-grid-row: ${(props) => props.msrowlap};
    -ms-grid-row-span: ${(props) => props.msrowspanlap};
    -ms-grid-column: ${(props) => props.mscollap};
    -ms-grid-column-span: ${(props) => props.mscolspanlap}; */

    margin-top: ${(props) => props.marginTopLaptop};
    margin-left: 0;

    svg {
      width: ${(props) => props.logoWidthLap};
      height: ${(props) => props.logoHeightLap};
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthDesktop}px) {
    svg {
      width: ${(props) => props.logoWidthDesk};
      height: ${(props) => props.logoHeightDesk};
    }
  }
`;
