import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, {
  ThemeProvider,
  createGlobalStyle,
  keyframes,
} from 'styled-components';
import { Normalize } from 'styled-normalize';
import 'core-js';
import '../../pageStyles/fonts.css';

import theme from './theme';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <ThemeProvider theme={theme}>
        <Normalize />
        <GlobalStyle />
        <Wrapper>{children}</Wrapper>
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const fadeIn = keyframes`
  from { opacity: 0}
  to { opacity: 1}
`;

const Wrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
`;

export const GlobalStyle = createGlobalStyle`
  

  html {
    color: ${props => props.theme.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  * {
    outline: none;
  }

  /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: ${props => props.theme.bodyFont};
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

h1, h2, h3, h4, h5 {
  font-family: ${props => props.theme.headerFont};
}

/* Remove all animations and transitions for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
} */
`;

export const Button = styled.button`
  min-width: 155px;
  appearance: none;
  box-shadow: none;
  border: 2px solid;
  border-radius: 22px;
  cursor: pointer;
  padding: 10px 25px;
  border-color: #fff;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  background: ${props =>
    props.secondary ? '#fff' : props.theme.primaryCherry};
  color: ${props => (props.secondary ? props.theme.primaryCherry : '#fff')};

  &:hover {
    border-color: ${props =>
      props.secondary ? props.theme.primaryCherry : '#fff'};
    background-color: ${props =>
      props.secondary ? '#fff' : props.theme.altCherry};
  }
`;
