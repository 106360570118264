import theme from '../components/Layout/theme';

export function isTablet(width) {
  return width >= theme.minWidthTablet && width < theme.minWidthLaptop;
}

export function isTabletOrWider(width) {
  if (!width) {
    return true;
  }
  return width >= theme.minWidthTablet;
}

export function isPortrait(width, height) {
  return width < height;
}

export function slideTypeFromData(data) {
  if (data.front && data.back) return 'card';
  if (data.image && data.image.fluid) return 'image';
  if (data.heading) return 'heading';
  return 'svg';
}

export function slideKeyFromData(data) {
  if (data.image) return data.image.id;
  if (data.heading) return data.heading;
  if (data.iconJson) return data.iconJson.file.url;
  return 'noKeyFromData';
}

export function fileSuffixFromWidth(width) {
  if (width < theme.minWidthTablet) return '_mobile';

  if (width < theme.minWidthLaptop) return '_tablet';

  if (width < theme.minWidthDesktop) return '_laptop';

  return '_desktop';
}
