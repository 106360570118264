import React from 'react';
import { navigate } from '@reach/router';
import { StyledFooterLinks } from './styles';

export default function FooterLinks({ links = [], scrollTo }) {
  return links.map(link => {
    const text = link[0];
    const destination = link[1];
    const linkName = link[2];

    if (destination instanceof Function) {
      return (
        <StyledFooterLinks
          key={text}
          onClick={() => {
            link[1]();
          }}
        >
          {text}
        </StyledFooterLinks>
      );
    }

    if (destination !== null) {
      return (
        <StyledFooterLinks
          key={text}
          onClick={() => {
            if (
              typeof window !== 'undefined' &&
              window.location.pathname !== '/'
            ) {
              navigate(`/#${linkName}`);
            } else {
              scrollTo(destination);
            }
          }}
        >
          {text}
        </StyledFooterLinks>
      );
    }

    return (
      <StyledFooterLinks
        key={text}
        href={linkName === 'culture' ? `/${linkName}/#` : `/#${linkName}`}
      >
        {text}
      </StyledFooterLinks>
    );
  });
}
