import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react';
import gsap from 'gsap/gsap-core';

const ScrollDispatchContext = React.createContext();

export default function Scroller({ children }) {
  const wrapper = useRef();
  const [section, setSection] = useState(null);

  const scrollTo = useCallback(identifier => {
    const elements = [
      ...wrapper.current.querySelectorAll('[data-scroller-id]'),
    ];
    const elem = elements[identifier];
    if (elem) {
      let object = { nr: window.scrollY };
      gsap.to(object, 0.5, {
        nr: elem.offsetTop,
        onUpdate: () => {
          window.scrollTo(0, object.nr);
        },
      });
    }
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const elements = [
        ...wrapper.current.querySelectorAll('[data-scroller-id]'),
      ];
      let active = '';
      elements.forEach(elem => {
        if (window.innerHeight * 0.5 > elem.getBoundingClientRect().y) {
          active = elem.dataset.scrollerId;
        }
      });

      setSection(active);
    };

    document.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);

    const current = [
      ...wrapper.current.querySelectorAll('[data-scroller-id]'),
    ].findIndex(elem => {
      return elem.dataset.scrollerId === window.location.hash.split('#')[1];
    });

    scrollTo(current > -1 ? current : 0);

    return () => {
      document.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []);

  useEffect(() => {
    if (section !== null) {
      history.pushState(null, null, `#${section}`);
    }
  }, [section]);

  return (
    <ScrollDispatchContext.Provider value={scrollTo}>
      <div
        ref={node => {
          wrapper.current = node;
        }}
      >
        {children}
      </div>
    </ScrollDispatchContext.Provider>
  );
}

export function Section({ Component, id }) {
  return (
    <section data-scroller-id={id}>
      <Component />
    </section>
  );
}

export function useScroll() {
  const scrollTo = useContext(ScrollDispatchContext);

  // if (scrollTo === undefined) {
  //   throw new Error('useScroll needs to be wrapped in a Scroller component');
  // }

  return { scrollTo };
}
